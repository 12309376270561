import { IcomoonIcon, IconUtils } from '@rocketmakers/armstrong-edge';
import { StringHelpers } from '../helpers/stringHelpers';

// export type Category =
//   | "health-wellness"
//   | "community"
//   | "behaviour"
//   | "enablers"
//   | "realestate"
//   | "deeptech"
//   | "finance"
//   | "biotech"
//   | "energy"
//   | "transport"
//   | "other"
//   | "food"
//   | "meetup"
//   | "games-agency"
//   | "software-agency"
//   | "communications-agency"
//   | "pr-agency";

export const TechForGoodCategories = [
  {
    name: 'environment',
    description:
      'organisations that are focused on reducing impact on our environment or utilise resources more sustainably.',
  },
  {
    name: 'health-wellness',
    description: 'organisations endeavouring to improve health and wellness at a societal or individual level.',
  },
  {
    name: 'behaviour',
    description: 'organisations that exist to drive behaviour change or overcome cultural challenges.',
  },
  {
    name: 'biotech',
    description:
      'the use of biology to develop new products, methods and organisms intended to improve human health and society.',
  },
  { name: 'energy', description: 'organisations that are working in renewables, energy monitoring and generation.' },
  {
    name: 'finance',
    description: 'finance focused organisations including many fintech solutions with a social focus.',
  },
  {
    name: 'food',
    description:
      'organisations focused on waste reduction, equitable food distribution and improvement of the food industry.',
  },
  {
    name: 'transport',
    description: 'organisations focused on improvements to the transport infrastructure or access.',
  },
  {
    name: 'deeptech',
    description:
      'organisations with the expressed objective of providing technology solutions based on substantial scientific or engineering challenges.',
  },
  { name: 'real-estate', description: 'Housing or home finding related organisations' },
  { name: 'other', description: 'organisations that don’t naturally fit into any of the other categories.' },
  {
    name: 'people',
    description: 'socially focused organisations looking at individual or community health interventions.',
  },
] as const;

export const SupportCategories = [
  { name: 'community', description: 'organisations that exist to bring purpose-led people together.' },
  { name: 'games-agency', description: 'organisations building computer games.' },
  { name: 'architect', description: 'organisations that design buildings and supervise their construction.' },
  {
    name: 'communications-agency',
    description: 'organisations providing communications services to impact-led companies.',
  },
  {
    name: 'software-agency',
    description:
      'organisations that build software (apps, websites etc) with an interest in social and environmental impact.',
  },
  {
    name: 'recruitment-agency',
    description:
      'organisations that help others to get a job, or to find the right candidate and who care about wider impact.',
  },
  {
    name: 'support-organisation',
    description: 'wider community of organisations that exist to support others who have an impact focus.',
  },
  {
    name: 'incubator',
    description:
      'socially or environmentally focused organisations that exist to accelerate or enable the growth of other organisations.',
  },
  { name: 'investor', description: 'organisation that invests in purpose-led organisations.' },
  { name: 'event', description: 'events and communities for purpose-led people.' },
] as const;

export const Categories = [
  ...TechForGoodCategories.map((item) => item.name),
  ...SupportCategories.map((item) => item.name),
] as const;

export type CategoryTuple = typeof Categories;
export type Category = CategoryTuple[number];
export type CategoryWithDescription = { name: Category; description: string };

export type CompanyType = 'tech-for-good' | 'support';

export interface ILocation {
  index: number;
  name: string;
  description?: string;
  city: string;
  postcode: string;
  category: Category;
  location: number[];
  webUrl: string;
  twitterUrl: string;
  type: CompanyType;
}

export const parseCategory = (cat: string): Category => {
  switch (cat) {
    case 'Health & Wellness':
      return 'health-wellness';
    case 'People & Social Impact':
      return 'people';
    case 'Social/People/Community':
      return 'community';
    case 'Behaviour/culture change':
    case 'Behaviour & Culture':
      return 'behaviour';
    case 'Real Estate':
      return 'real-estate';
    default:
      return cat.toLowerCase().replace(/\//g, '-').replace(/\s+/g, '-') as Category;
  }
};

export const getFriendlyCategoryName = (cat: Category): string => {
  switch (cat) {
    case 'health-wellness':
      return 'Health & Wellbeing';
    case 'behaviour':
      return 'Behaviour & Culture';
    case 'people':
      return 'People & Community';
    default:
      return StringHelpers.slugToSentence(StringHelpers.toTitleCase(cat));
  }
};
