import { ModalProvider } from '@rocketmakers/armstrong-edge';
import { useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import { AppContextQuery } from '../generated/graphql';
import { Categories, Category, ILocation, parseCategory, CompanyType } from '../typings/data';
import { orderBy } from 'lodash';

interface IAppContext {
  allLocations: ILocation[];
  locations: ILocation[];
  filteredLocations: ILocation[];
  filterMode: CompanyType;
  setFilterMode: (FilterMode: CompanyType) => void;
  filterCategories: Category[];
  setFilterCategories: (category: Category) => void;
  resetFilterCategories?: () => void;
}

export const AppContext = React.createContext<IAppContext>({
  allLocations: [],
  locations: [],
  filteredLocations: [],
  filterMode: 'tech-for-good',
  setFilterMode: () => {},
  filterCategories: [...Categories],
  setFilterCategories: () => {},
  resetFilterCategories: () => {},
});

const AppProvider: React.FC = ({ children }) => {
  const [filterMode, setFilterMode] = React.useState<CompanyType>('tech-for-good');
  const [filterCategories, setFilterCategories] = React.useState<Category[]>([]);

  const updateCategories = (category: Category) => {
    if (filterCategories.indexOf(category) === -1) {
      setFilterCategories((cs) => cs.concat(category));
    } else {
      setFilterCategories(filterCategories.filter((c) => c !== category));
    }
  };

  React.useEffect(() => {
    setFilterCategories([]);
  }, [filterMode]);

  const data = useStaticQuery<AppContextQuery>(graphql`
    query AppContext {
      techForGood: allGoogleBristolandBathonlySheet {
        nodes {
          company
          category
          description
          city
          postcode
          latLng
          uRL
          twitter
        }
      }
      support: allGoogleAgencySupportEditionSheet {
        nodes {
          company
          category
          description
          city
          postcode
          latLng
          uRL
          tWITTER
        }
      }
    }
  `);

  const allLocations = React.useMemo(() => {
    //const nodes: any[] = [...data.techForGood.nodes, ...data.support.nodes];
    const techForGoodNodes = data.techForGood.nodes
      .filter((n) => !!n.category)
      .filter((n) => !!n.latLng)
      .map((n, i) => ({
        index: i,
        name: n.company!,
        description: n.description,
        postcode: n.postcode!,
        city: n.city!,
        webUrl: n.uRL,
        twitterUrl: n.twitter,
        category: parseCategory(n.category!),
        location: [parseFloat(n.latLng!.split(',')[0]), parseFloat(n.latLng!.split(',')[1])],
        type: 'tech-for-good',
      }));

    const supportNodes = data.support.nodes
      .filter((n) => !!n.category)
      .filter((n) => !!n.latLng)
      .map((n, i) => ({
        index: i,
        name: n.company!,
        description: n.description,
        postcode: n.postcode!,
        city: n.city!,
        webUrl: n.uRL,
        twitterUrl: n.tWITTER,
        category: parseCategory(n.category!),
        location: [parseFloat(n.latLng!.split(',')[0]), parseFloat(n.latLng!.split(',')[1])],
        type: 'support',
      }));

    return [...techForGoodNodes, ...supportNodes] as any[];
  }, [filterMode, data, filterCategories]);

  const locations = React.useMemo(() => {
    return allLocations.filter((l) => l.type === filterMode);
  }, [filterMode, data, filterCategories]);

  const filteredLocations = React.useMemo(() => {
    if (filterCategories.length === 0) {
      return orderBy(locations, (l) => l.category);
    }
    return orderBy(
      locations.filter((l) => filterCategories.indexOf(l.category) !== -1),
      (l) => l.category
    );
  }, [locations, filterCategories]);

  return (
    <ModalProvider>
      <AppContext.Provider
        value={{
          allLocations,
          locations,
          filteredLocations,
          filterMode,
          filterCategories,
          setFilterCategories: updateCategories,
          setFilterMode,
          resetFilterCategories: () => setFilterCategories([]),
        }}
      >
        {children}
      </AppContext.Provider>
    </ModalProvider>
  );
};

export default ({ element }) => <AppProvider>{element}</AppProvider>;
