export namespace StringHelpers {
  /**
   * Generates a correct pluralisation of a word
   * @param word The word to pluralise
   */
  export const pluralise = (word: string) => {
    return word.endsWith('s') ? `${word}'s` : `${word}s`;
  };

  /**
   * Generates a correct pluralisation of a word
   * @param word The word to pluralise
   * @param length The length of the collection
   */
  export const pluraliseCollection = (word: string, length: number) => {
    if (length === 1) {
      return `${length} ${word}`;
    }
    return word.endsWith('s') ? `${length} ${word}'s` : `${length} ${word}s`;
  };

  /**
   * Converts a 'Sentence to title case'
   * @param str The sentence to casse
   */
  export const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  /**
   * Converts a slug to a sentence
   * @param str The slug to sentence
   */
  export const slugToSentence = (str) => {
    return str.replace(/-/g, ' ');
  };

  /**
   * Converts a string to sentence case (space on uppercase)
   * @param str The word to convert
   */
  export const toSentence = (str) => {
    return str.replace(/([A-Z])/g, ' $1').trim();
  };
}
